<template lang="html">
	<v-layout column class="pb-5" style="padding-top:140px">
		<v-flex class="page-title" pb-10 :style="{'font-size':$vuetify.breakpoint.smAndUp?'70px':'50px'}">
			<v-layout row>
				<v-flex xs12 sm12 md10 lg10 xl8>
					<span class="text-appear" style="margin-right:20px;display:inline-block" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.8s':'0.1s'}">Terms</span>
					<span class="text-appear" style="margin-right:20px;display:inline-block" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.83s':'0.13s'}">Of</span>
					<span class="text-appear" style="margin-right:20px;display:inline-block" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.86s':'0.16s'}">Use</span>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex class="my-15">
			<v-divider></v-divider>
		</v-flex>
		<v-flex class="py-10">
			<v-layout row>
				<v-flex xs12 md10>
					<v-layout column>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Website Terms of Use:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>The use of any product, service or feature (the "Materials") available through the internet websites accessible at imagine.tech (the "Website") by any user of the Website ("you" or "your") shall be governed by the following Terms of Use:</p>
									<ul>
										<li>This Website is provided by Imagine ("Imagine"), and may be used for informational purposes only. By using the site or downloading the Materials from the site, you agree to abide by the terms and conditions set forth in this notice. If you do not agree to abide by these terms and conditions please do not use the Website or download materials from the Website.</li>
										<li>This Site, including all Materials present (excluding any applicable third party materials) , is the property of Imagine and is copyrighted and protected by worldwide copyright laws and treaty provisions. You agree to comply with all copyright laws worldwide in your use of this Website and to prevent any unauthorized copying of the Materials. Imagine does not grant any express or implied rights under any patents, trademarks, copyrights or trade secret information.</li>
										<li>Imagine has business relationships with a number of customers, suppliers, and others. For convenience and simplicity, words like joint venture, partnership, and partner are used to indicate business relationships involving common activities and interests, and those words may not indicate precise legal relationships.</li>
									</ul>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Limited License:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>Subject to the terms and conditions set forth in these Terms of Use, Imagine grants you a non-exclusive, non-transferable, limited right to access, use and display this site and the materials thereon. You agree not to interrupt or attempt to interrupt the operation of the site in any way.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Third Party Content
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>The Website makes information of third parties available, including articles, analyst reports, news reports, tools to facilitate calculation, company information and other data (the "Third Party Content"). You acknowledge and agree that the Third Party Content is not created or endorsed by Imagine nor are any products or services offered through it. The provision of Third Party Content is for general informational purposes only and does not constitute a recommendation or solicitation to make any type of investment decision. In addition, the Third Party Content is not intended to provide tax, legal or investment advice. You acknowledge that the Third Party Content provided to you is obtained from sources believed to be reliable, but that no guarantees are made by the Website or the providers of the Third Party Content as to its accuracy, completeness, timeliness. You agree not to hold the Website, any business offering products or services through the Website or any provider of Third Party Content liable for any investment decision or other transaction you may make based on your reliance on or use of such data, or any liability that may arise due to delays or interruptions in the delivery of the Third Party Content for any reason.</p>
									<p>By using any Third Party Content, you may leave this Website and be directed to an external website, or to a website maintained by an entity other than Imagine. If you decide to visit any such site, you do so at your own risk and it is your responsibility to take all protective measures to guard against viruses or other destructive elements. Imagine makes no warranty or representation regarding, and does not endorse, any linked Websites or the information appearing thereon or any of the products or services described thereon. Links do not imply that Imagine or this Site sponsors, endorses, is affiliated or associated with, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is authorized to use any trademark, trade name, logo or copyright symbol of Imagine or any of its affiliates or subsidiaries.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									NO WARRANTIES
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>This site, the information and materials on the site, and any software made available on the site, are provided "as is" without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non infringement, or fitness for any particular purpose. There is no warranty of any kind, express or implied, regarding third party content. There is also no warranty that this site will be free of any computer viruses. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Limitation of damages:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>In no event shall Imagine or any of its subsidiaries or affiliates be liable to any entity for any direct, indirect, special, consequential or other damages (including, without limitation, any lost profits, business interruption, loss of information or programs or other data on your information handling system) that are related to the use of, or the inability to use, the content, materials, and functions of the website or any linked website, even if Imagine is expressly advised of the possibility of such damages.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Disclaimer:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>The website may contain inaccuracies and typographical and clerical errors. Imagine expressly disclaims any obligation to update this site or any of the materials on this site. Imagine does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion, statement or other information displayed or distributed through the site. you acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. Imagine reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the site. Imagine may make any other changes to the site, the materials and the products, programs, services or prices (if any) described in the site at any time without notice.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Indemnity:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>You agree to indemnify and hold harmless Imagine, its subsidiaries and affiliates from any claim, cost, expense, judgment or other loss relating to your use of this Website, including without limitation of the foregoing, any action you take which is in violation of the terms and conditions of these Terms of Use.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									Changes:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>Imagine reserves the right, at its sole discretion, to change, modify, add or remove any portion of these Terms of Use in whole or in part, at any time. Changes in these Terms of Use will be effective when notice of such change is posted. Your continued use of the Website after any changes to these Terms of Use are posted will be considered acceptance of those changes. Imagine may terminate, change, suspend or discontinue any aspect of the Imagine Website, including the availability of any features of the Site, at any time. Imagine may also impose limits on certain features and services or restrict your access to parts or all of the Website without notice or liability. Imagine may terminate the authorization, rights and license given above and, upon such termination, you shall immediately destroy all Materials.</p>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="terms-paragraph">
							<v-layout column>
								<v-flex class="terms-paragraph-title" :style="{'font-size':$vuetify.breakpoint.smAndUp?'26px':'22px'}">
									International Users and Choice of Law:
								</v-flex>
								<v-flex class="terms-paragraph-body" :style="{'font-size':$vuetify.breakpoint.smAndUp?'18px':'16px'}">
									<p>This Site is controlled, operated and administered by Imagine from its offices within Duluth, State of Georgia, United States of America. Imagine makes no representation that materials at this site are appropriate or available for use at other locations outside of the United States of America and access to them from territories where their contents are illegal is prohibited. You may not use the Website or export the Materials in violation of any applicable export laws and regulations. If you access this Site from a location outside of the United States of America, you are responsible for compliance with all local laws. These Terms of Use shall be governed by the laws of the State of Georgia, the United States of America without giving effect to its conflict of laws provisions. You agree that the appropriate court in Duluth, Georgia will have the exclusive jurisdiction to resolve all disputes arising under these Terms of Use, and you consent to personal jurisdiction in such forum.</p>
									<p>These Terms of Use constitutes the entire agreement between Imagine and you with respect to your use of the Website. Any cause of action you may have with respect to your use of the Website must be commenced within one (1) year after the claim or cause of action arises. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of these Terms of Use, and the remainder of these Terms of Use shall continue in full force and effect.</p>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<!--<v-flex style="margin-top:50px">
			<ContactUs contactHeader="Curious about what Imagine could do for your business?" contactSubtitle="Let us know a few details and an Imagine expert will be in touch." :interestedProducts="[]" />
		</v-flex>-->
	</v-layout>
</template>

<script>
//import ContactUs from '../ContactUs'
export default {
	name: 'TermsOfUsexPage',
	components: {
		//ContactUs
	},
	created() {
	}
}
</script>

<style lang="css" scoped>
.page-title{
	font-family: JostBold;
	line-height:1.2;
	animation-delay:0.8s;
}
.terms-paragraph{
	padding:20px 0;
}
.terms-paragraph-title{
	font-family: JostMedium;
	margin-bottom:20px;
}
.text-appear{
	transform:translateY(150%);
	animation-name:text-appear;
	animation-duration:0.7s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	opacity:0
}
@keyframes text-appear {
	from{
		transform:translateY(150%);
		opacity:0;
	}
	to{
		transform:translateY(0);
		opacity:1
	}
}
</style>
